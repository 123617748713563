:root {
  --z-index-navbar: 1000;
  --navbar-height: 60px;
}

.panel-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}
.root{
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
}
.chart-card{
  background-color: #fff;;
    position: relative;
    flex: 1 0 auto;
    margin: 1em;
    padding: 1em;
    border-radius: 5px;
}
