.p-breadcrumb ul li .p-menuitem-link {
    transition: background-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
  }
  .p-breadcrumb ul li .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #d14b4b;
  }
  .p-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
    color: #333333;
  }
  .p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon {
    color: #333333;
  }
  .p-breadcrumb ul li.p-breadcrumb-chevron {
    margin: 0 0.5rem 0 0.5rem;
    color: #333333;
  }
  .p-breadcrumb ul li:last-child .p-menuitem-text {
    color: #333333;
  }
  .p-breadcrumb ul li:last-child .p-menuitem-icon {
    color: #333333;
  }
  
  .p-contextmenu {
    padding: 0;
    background: #ffffff;
    color: #333333;
    border: 1px solid #c8c8c8;
    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    width: 12.5rem;
  }
  .p-contextmenu .p-menuitem-link {
    padding: 0.857rem;
    color: #333333;
    border-radius: 0;
    transition: background-color 0.2s, box-shadow 0.2s;
    user-select: none;
  }
  .p-contextmenu .p-menuitem-link .p-menuitem-text {
    color: #333333;
  }
  .p-contextmenu .p-menuitem-link .p-menuitem-icon {
    color: #333333;
    margin-right: 0.5rem;
  }
  .p-contextmenu .p-menuitem-link .p-submenu-icon {
    color: #333333;
  }
  .p-contextmenu .p-menuitem-link:not(.p-disabled):hover {
    background: #eaeaea;
  }
  .p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    // color: #333333;
  }
  .p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    // color: #333333;
  }
  .p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    // color: #333333;
  }
  .p-contextmenu .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.2rem #d14b4b;
  }
  .p-contextmenu .p-submenu-list {
    padding: 0;
    background: #ffffff;
    border: 1px solid #c8c8c8;
    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  }
  .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
    background: #eaeaea;
  }
  .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
    color: #333333;
  }
  .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
    color: #333333;
  }
  .p-contextmenu .p-menu-separator {
    border-top: 1px solid #d8dae2;
    margin: 0;
  }
  .p-contextmenu .p-submenu-icon {
    font-size: 0.875rem;
  }
  
  .p-megamenu {
    padding: 0.5rem;
    background: #ffffff;
    color: #333333;
    border: 1px solid #c8c8c8;
    border-radius: 3px;
  }
  .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link {
    padding: 0.857rem;
    color: #333333;
    border-radius: 3px;
    transition: background-color 0.2s, box-shadow 0.2s;
    user-select: none;
  }
  .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
    color: #333333;
  }
  .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
    color: #333333;
    margin-right: 0.5rem;
  }
  .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
    color: #333333;
    margin-left: 0.5rem;
  }
  .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
    background: #eaeaea;
  }
  .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    // color: #333333;
  }
  .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    // color: #333333;
  }
  .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    // color: #333333;
  }
  .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.2rem #d14b4b;
  }
  .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link,
  .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
    background: #eaeaea;
  }
  .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text,
  .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #333333;
  }
  .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
  .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #333333;
  }
  .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon,
  .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #333333;
  }
  .p-megamenu .p-menuitem-link {
    padding: 0.857rem;
    color: #333333;
    border-radius: 0;
    transition: background-color 0.2s, box-shadow 0.2s;
    user-select: none;
  }
  .p-megamenu .p-menuitem-link .p-menuitem-text {
    color: #333333;
  }
  .p-megamenu .p-menuitem-link .p-menuitem-icon {
    color: #333333;
    margin-right: 0.5rem;
  }
  .p-megamenu .p-menuitem-link .p-submenu-icon {
    color: #333333;
  }
  .p-megamenu .p-menuitem-link:not(.p-disabled):hover {
    background: #eaeaea;
  }
  .p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    // color: #333333;
  }
  .p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    // color: #333333;
  }
  .p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    // color: #333333;
  }
  .p-megamenu .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.2rem #d14b4b;
  }
  .p-megamenu .p-megamenu-panel {
    background: #ffffff;
    color: #333333;
    border: 1px solid #c8c8c8;
    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  }
  .p-megamenu .p-megamenu-submenu-header {
    margin: 0;
    padding: 0.857rem;
    color: #333333;
    background: #f4f4f4;
    font-weight: 700;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }
  .p-megamenu .p-megamenu-submenu {
    padding: 0;
    width: 12.5rem;
  }
  .p-megamenu .p-megamenu-submenu .p-menu-separator {
    border-top: 1px solid #d8dae2;
    margin: 0;
  }
  .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
    background: #eaeaea;
  }
  .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
    color: #333333;
  }
  .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
    color: #333333;
  }
  .p-megamenu.p-megamenu-vertical {
    width: 12.5rem;
    padding: 0;
  }
  
  .p-menu {
    padding: 0;
    background: #ffffff;
    color: #333333;
    border: 1px solid #c8c8c8;
    border-radius: 3px;
    width: 12.5rem;
  }
  .p-menu .p-menuitem-link {
    padding: 0.857rem;
    color: #333333;
    border-radius: 0;
    transition: background-color 0.2s, box-shadow 0.2s;
    user-select: none;
  }
  .p-menu .p-menuitem-link .p-menuitem-text {
    color: #333333;
  }
  .p-menu .p-menuitem-link .p-menuitem-icon {
    color: #333333;
    margin-right: 0.5rem;
  }
  .p-menu .p-menuitem-link .p-submenu-icon {
    color: #333333;
  }
  .p-menu .p-menuitem-link:not(.p-disabled):hover {
    background: #eaeaea;
  }
  .p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    // color: #333333;
  }
  .p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    // color: #333333;
  }
  .p-menu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    // color: #333333;
  }
  .p-menu .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.2rem #d14b4b;
  }
  .p-menu.p-menu-overlay {
    background: #ffffff;
    border: 1px solid #c8c8c8;
    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  }
  .p-menu .p-submenu-header {
    margin: 0;
    padding: 0.857rem;
    color: #333333;
    background: #f4f4f4;
    font-weight: 700;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }
  .p-menu .p-menu-separator {
    border-top: 1px solid #d8dae2;
    margin: 0;
  }
  
  .p-menubar {
    padding: 0.5rem;
    background: #ffffff;
    color: #333333;
    border: 1px solid #c8c8c8;
    border-radius: 3px;
  }
  .p-menubar .p-menuitem-link {
    padding: 0.857rem;
    color: #333333;
    border-radius: 0;
    transition: background-color 0.2s, box-shadow 0.2s;
    user-select: none;
  }
  .p-menubar .p-menuitem-link .p-menuitem-text {
    color: #333333;
  }
  .p-menubar .p-menuitem-link .p-menuitem-icon {
    color: #333333;
    margin-right: 0.5rem;
  }
  .p-menubar .p-menuitem-link .p-submenu-icon {
    color: #333333;
  }
  .p-menubar .p-menuitem-link:not(.p-disabled):hover {
    background: #eaeaea;
  }
  .p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    // color: #333333;
  }
  .p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    // color: #333333;
  }
  .p-menubar .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    // color: #333333;
  }
  .p-menubar .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.2rem #d14b4b;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
    padding: 0.857rem;
    color: #333333;
    border-radius: 3px;
    transition: background-color 0.2s, box-shadow 0.2s;
    user-select: none;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
    color: #333333;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
    color: #333333;
    margin-right: 0.5rem;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
    color: #333333;
    margin-left: 0.5rem;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
    background: #eaeaea;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    // color: #333333;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    // color: #333333;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    // color: #333333;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.2rem #d14b4b;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link,
  .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
    background: #eaeaea;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text,
  .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #333333;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
  .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #333333;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon,
  .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #333333;
  }
  .p-menubar .p-submenu-list {
    padding: 0;
    background: #ffffff;
    border: 1px solid #c8c8c8;
    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    width: 12.5rem;
  }
  .p-menubar .p-submenu-list .p-menu-separator {
    border-top: 1px solid #d8dae2;
    margin: 0;
  }
  .p-menubar .p-submenu-list .p-submenu-icon {
    font-size: 0.875rem;
  }
  .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link {
    background: #eaeaea;
  }
  .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
    color: #333333;
  }
  .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
    color: #333333;
  }
  
  @media screen and (max-width: 960px) {
    .p-menubar {
      position: relative;
    }
    .p-menubar .p-menubar-button {
      display: flex;
      width: 2rem;
      height: 2rem;
      color: #333333;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    }
    .p-menubar .p-menubar-button:hover {
      color: #333333;
      background: #eaeaea;
    }
    .p-menubar .p-menubar-button:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem #d14b4b;
    }
    .p-menubar .p-menubar-root-list {
      position: absolute;
      display: none;
      padding: 0;
      background: #ffffff;
      border: 1px solid #c8c8c8;
      box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
      width: 100%;
    }
    .p-menubar .p-menubar-root-list .p-menu-separator {
      border-top: 1px solid #d8dae2;
      margin: 0;
    }
    .p-menubar .p-menubar-root-list .p-submenu-icon {
      font-size: 0.875rem;
    }
    .p-menubar .p-menubar-root-list > .p-menuitem {
      width: 100%;
      position: static;
    }
    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
      padding: 0.857rem;
      color: #333333;
      border-radius: 0;
      transition: background-color 0.2s, box-shadow 0.2s;
      user-select: none;
    }
    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
      color: #333333;
    }
    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
      color: #333333;
      margin-right: 0.5rem;
    }
    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
      color: #333333;
    }
    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
      background: #eaeaea;
    }
    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
      // color: #333333;
    }
    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
      // color: #333333;
    }
    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
      // color: #333333;
    }
    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: inset 0 0 0 0.2rem #d14b4b;
    }
    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link > .p-submenu-icon {
      margin-left: auto;
      transition: transform 0.2s;
    }
    .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
      transform: rotate(-180deg);
    }
    .p-menubar .p-menubar-root-list .p-submenu-list {
      width: 100%;
      position: static;
      box-shadow: none;
      border: 0 none;
    }
    .p-menubar .p-menubar-root-list .p-submenu-list .p-submenu-icon {
      transition: transform 0.2s;
      transform: rotate(90deg);
    }
    .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
      transform: rotate(-90deg);
    }
    .p-menubar .p-menubar-root-list .p-menuitem {
      width: 100%;
      position: static;
    }
    .p-menubar .p-menubar-root-list ul li a {
      padding-left: 2.571rem;
    }
    .p-menubar .p-menubar-root-list ul li ul li a {
      padding-left: 4.285rem;
    }
    .p-menubar .p-menubar-root-list ul li ul li ul li a {
      padding-left: 5.999rem;
    }
    .p-menubar .p-menubar-root-list ul li ul li ul li ul li a {
      padding-left: 7.713rem;
    }
    .p-menubar .p-menubar-root-list ul li ul li ul li ul li ul li a {
      padding-left: 9.427rem;
    }
    .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
      display: flex;
      flex-direction: column;
      top: 100%;
      left: 0;
      z-index: 1;
    }
  }
  .p-panelmenu .p-panelmenu-header > a {
    padding: 0.857rem 1rem;
    border: 1px solid #007ad9;
    color: #ffffff;
    background: #007ad9;
    font-weight: 700;
    border-radius: 3px;
    transition: background-color 0.2s, box-shadow 0.2s;
  }
  .p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon {
    margin-right: 0.5rem;
  }
  .p-panelmenu .p-panelmenu-header > a .p-menuitem-icon {
    margin-right: 0.5rem;
  }
  .p-panelmenu .p-panelmenu-header > a:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #d14b4b;
  }
  .p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled) > a:hover {
    background: #dbdbdb;
    border-color: #dbdbdb;
    // color: #333333;
  }
  .p-panelmenu .p-panelmenu-header.p-highlight {
    margin-bottom: 0;
  }
  .p-panelmenu .p-panelmenu-header.p-highlight > a {
    background: #007ad9;
    border-color: #007ad9;
    color: #ffffff;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled) > a:hover {
    border-color: #005b9f;
    background: #005b9f;
    color: #ffffff;
  }
  .p-panelmenu .p-panelmenu-content {
    padding: 0;
    border: 1px solid #c8c8c8;
    background: #ffffff;
    color: #333333;
    margin-bottom: 2px;
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
    padding: 0.857rem;
    color: #333333;
    border-radius: 0;
    transition: background-color 0.2s, box-shadow 0.2s;
    user-select: none;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-text {
    color: #333333;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-icon {
    color: #333333;
    margin-right: 0.5rem;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-submenu-icon {
    color: #333333;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover {
    background: #eaeaea;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    // color: #333333;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    // color: #333333;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    // color: #333333;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.2rem #d14b4b;
  }
  .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-panelmenu-icon {
    margin-right: 0.5rem;
  }
  .p-panelmenu .p-panelmenu-content .p-submenu-list:not(.p-panelmenu-root-submenu) {
    padding: 0 0 0 1rem;
  }
  .p-panelmenu .p-panelmenu-panel {
    margin-bottom: 2px;
  }
  
  .p-slidemenu {
    padding: 0;
    background: #ffffff;
    color: #333333;
    border: 1px solid #c8c8c8;
    border-radius: 3px;
    width: 12.5rem;
  }
  .p-slidemenu .p-menuitem-link {
    padding: 0.857rem;
    color: #333333;
    border-radius: 0;
    transition: background-color 0.2s, box-shadow 0.2s;
    user-select: none;
  }
  .p-slidemenu .p-menuitem-link .p-menuitem-text {
    color: #333333;
  }
  .p-slidemenu .p-menuitem-link .p-menuitem-icon {
    color: #333333;
    margin-right: 0.5rem;
  }
  .p-slidemenu .p-menuitem-link .p-submenu-icon {
    color: #333333;
  }
  .p-slidemenu .p-menuitem-link:not(.p-disabled):hover {
    background: #eaeaea;
  }
  .p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    // color: #333333;
  }
  .p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    // color: #333333;
  }
  .p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    // color: #333333;
  }
  .p-slidemenu .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.2rem #d14b4b;
  }
  .p-slidemenu.p-slidemenu-overlay {
    background: #ffffff;
    border: 1px solid #c8c8c8;
    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  }
  .p-slidemenu .p-slidemenu-list {
    padding: 0;
    background: #ffffff;
    border: 1px solid #c8c8c8;
    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  }
  .p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link {
    background: #eaeaea;
  }
  .p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-text {
    color: #333333;
  }
  .p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon, .p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon {
    color: #333333;
  }
  .p-slidemenu .p-slidemenu-separator {
    border-top: 1px solid #d8dae2;
    margin: 0;
  }
  .p-slidemenu .p-slidemenu-icon {
    font-size: 0.875rem;
  }
  .p-slidemenu .p-slidemenu-backward {
    padding: 0.857rem;
    color: #333333;
  }
  
  .p-steps .p-steps-item .p-menuitem-link {
    background: transparent;
    transition: background-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
    background: #ffffff;
  }
  .p-steps .p-steps-item .p-menuitem-link .p-steps-number {
    color: #333333;
    border: 1px solid #dee2e6;
    background: #ffffff;
    min-width: 2rem;
    height: 2rem;
    line-height: 2rem;
    font-size: 1.143rem;
    z-index: 1;
    border-radius: 50%;
  }
  .p-steps .p-steps-item .p-menuitem-link .p-steps-title {
    margin-top: 0.5rem;
    color: #848484;
  }
  .p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #d14b4b;
  }
  .p-steps .p-steps-item.p-highlight .p-steps-number {
    background: #c0392b;
    color: #ffffff;
  }
  .p-steps .p-steps-item.p-highlight .p-steps-title {
    font-weight: 700;
    color: #333333;
  }
  .p-steps .p-steps-item:before {
    content: " ";
    border-top: 1px solid #d8dae2;
    width: 100%;
    top: 50%;
    left: 0;
    display: block;
    position: absolute;
    margin-top: -1rem;
  }
  
  .p-tabmenu .p-tabmenu-nav {
    background: #ffffff;
    border: solid rgba(0, 0, 0, 0.12);
    border-width: 0 0 1px 0;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
    margin-right: 0;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    border: none;
    border-width: 0 0 0 0;
    border-color: transparent transparent transparent transparent;
    background: #ffffff;
    color: rgba(0, 0, 0, 0.6);
    padding: 1rem 1.5rem;
    font-weight: 500;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    transition: none;
    margin: 0 0 0 0;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
    margin-right: 0.5rem;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
  /* #b33939 */
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
    /* background: rgba(63, 81, 181, 0.04); */
    background-color: rgb(52, 152, 219,0.04);
  
  
    border-color: transparent;
    color: rgba(0, 0, 0, 0.6);
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    background: #ffffff;
    border-color: transparent;
    color: #3498db;
  }
  
  .p-tabmenu .p-tabmenu-nav {
    position: relative;
  }
  .p-tabmenu .p-tabmenu-nav li .p-menuitem-link {
    transition: background-color 0.2s;
    border-radius: 0;
  }
  .p-tabmenu .p-tabmenu-nav li .p-menuitem-link > .p-ink {
    /* background-color: rgba(63, 81, 181, 0.16); */
    background-color: rgb(52, 152, 219,0.16);
  
  }
  .p-tabmenu .p-tabmenu-nav li .p-menuitem-link:focus {
    /* background-color: rgba(63, 81, 181, 0.12); */
    background-color: rgb(52, 152, 219,0.12);
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenu-ink-bar {
    display: block;
    position: absolute;
    bottom: 0;
    height: 2px;
    background-color: #3498db;
    transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);
  }
  
  /* .p-tabmenu .p-tabmenu-nav {
    background: transparent;
    border: 0 none;
    border-width: 1px;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
    margin-right: 2px;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    border: 1px solid #007ad9;
    border-width: 1px;
    border-color: #007ad9;
    background: #007ad9;
    color: #ffffff;
    padding: 0.857rem 1rem;
    font-weight: 700;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    transition: background-color 0.2s, box-shadow 0.2s;
    margin: 0 0 -1px 0;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
    margin-right: 0.5rem;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #d14b4b;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
    background: #dbdbdb;
    border-color: #dbdbdb;
    color: #333333;
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    background: #007ad9;
    border-color: #007ad9;
    color: #ffffff;
  } */
  
  .p-tieredmenu {
    padding: 0;
    background: #ffffff;
    color: #333333;
    border: 1px solid #c8c8c8;
    border-radius: 3px;
    width: 12.5rem;
  }
  .p-tieredmenu .p-menuitem-link {
    padding: 0.857rem;
    color: #333333;
    border-radius: 0;
    transition: background-color 0.2s, box-shadow 0.2s;
    user-select: none;
  }
  .p-tieredmenu .p-menuitem-link .p-menuitem-text {
    color: #333333;
  }
  .p-tieredmenu .p-menuitem-link .p-menuitem-icon {
    color: #333333;
    margin-right: 0.5rem;
  }
  .p-tieredmenu .p-menuitem-link .p-submenu-icon {
    color: #333333;
  }
  .p-tieredmenu .p-menuitem-link:not(.p-disabled):hover {
    background: #eaeaea;
  }
  .p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    // color: #333333;
  }
  .p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    // color: #333333;
  }
  .p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    // color: #333333;
  }
  .p-tieredmenu .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.2rem #d14b4b;
  }
  .p-tieredmenu.p-tieredmenu-overlay {
    background: #ffffff;
    border: 1px solid #c8c8c8;
    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  }
  .p-tieredmenu .p-submenu-list {
    padding: 0;
    background: #ffffff;
    border: 1px solid #c8c8c8;
    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  }
  .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
    background: #eaeaea;
  }
  .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
    color: #333333;
  }
  .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
    color: #333333;
  }
  .p-tieredmenu .p-menu-separator {
    border-top: 1px solid #d8dae2;
    margin: 0;
  }
  .p-tieredmenu .p-submenu-icon {
    font-size: 0.875rem;
  }